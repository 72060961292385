import './styles/app.scss';

var avatarForm = document.querySelector('#avatar_file');
avatarForm.addEventListener("change", function(){uploadAvatar(this.form)});
function uploadAvatar(target)
{
    var fd = new FormData(target);
    var xhr = new XMLHttpRequest();	
    xhr.open("POST", target.action, true);	  
    xhr.onload = function(e)
    {
	location.reload(true);
	//var JSONObject = xhr.responseText;
	//var answer = JSON.parse(JSONObject);
	//document.querySelector('.avatar > a > img').src =  "/uploads/avatars/large/" + answer.path;
    };							
    xhr.send(fd);
}

//var updateSubscriptionForm = document.querySelector('#update_sub');
//avatarForm.addEventListener("click", function(){updateSubscription(this.form)});
//function updateSubscription(target)
//{
//    alert('yo');
//    var fd = new FormData(target);
//    var xhr = new XMLHttpRequest();	
//    xhr.open("POST", target.action, true);	  
//    xhr.onload = function(e)
//    {
//	location.reload(true);
	//var JSONObject = xhr.responseText;
	//var answer = JSON.parse(JSONObject);
	//document.querySelector('.avatar > a > img').src =  "/uploads/avatars/large/" + answer.path;
//    };							
//    xhr.send(fd);
//}

//var cancelSubscriptionForm = document.querySelector('#cancel_subscription');
//cancelSubscriptionForm.addEventListener("click", function(event){
//    var xhr = new XMLHttpRequest();
//    alert('yo');
//    xhr.onload = function(e)
//    {
//	alert('hello2');
//	location.reload(true);
//    };
//    xhr.open("POST", "cancel-subscription");
//    xhr.send();
//});

var userPrivacyForm = document.querySelector('#privacy_public');
userPrivacyForm.addEventListener("change", function(){userPrivacySwitch(this.form)});
function userPrivacySwitch(target)
{		
    var formData = new FormData(target);	
    var xhr = new XMLHttpRequest();	
    xhr.open("POST", target.action, true);
    xhr.onload = function(e)
    {
	var JSONObject = xhr.responseText;
	var answer = JSON.parse(JSONObject);

	if (answer.privacy == false)
	{
	    document.querySelector('#z').classList.remove("eye-opened");
	    document.querySelector('#z').classList.add("eye-closed");
	}
	else if (answer.privacy == true)
	{
	    document.querySelector('#z').classList.remove("eye-closed");
	    document.querySelector('#z').classList.add("eye-opened");
	}
    };							 
    xhr.send(formData);
}

var bgColorForm = document.querySelector('#bg_color_color');
bgColorForm.addEventListener("change", function(){bgColorChange(this.form)});
function bgColorChange(target)
{
    var formData = new FormData(target);	
    var xhr = new XMLHttpRequest();	
    xhr.open("POST", target.action, true);
    xhr.onload = function(e)
    {
	var JSONObject = xhr.responseText;
	var answer = JSON.parse(JSONObject);

	document.body.style.backgroundColor = answer.background_color;
    };							 
    xhr.send(formData);
}

var changeLocaleForm = document.querySelector('#lang_locale');
changeLocaleForm.addEventListener("change", function(){changeLocale(this.form)});
function changeLocale(target)
{
    var formData = new FormData(target);	
    var xhr = new XMLHttpRequest();	
    xhr.open("POST", target.action, true);
    xhr.onload = function(e)
    {
	var JSONObject = xhr.responseText;
	var answer = JSON.parse(JSONObject);
	window.location = window.location.href.replace('/'+answer.old_locale+'/', '/'+answer.locale+'/');
    };							 
    xhr.send(formData);
}

var searchForm = document.querySelector('#searchy_keyword');
searchForm.addEventListener("keyup", function(){search(this)});
function search(target) 
{
    var fd = new FormData();
    fd.append("keyword", target.value);
    var xhr = new XMLHttpRequest();
    
    var length = target.value.length;
    
    xhr.onload = function(e)
    {			
	document.querySelector('#search-results').innerHTML = xhr.responseText;
	
	if (length > 1)
	{
	    document.querySelector('#slidy').classList.add("animation-none"); // on rajoute une classe
	}
	
	if (length < 1)
	{
	    document.querySelector('#slidy').classList.add("slidy-down"); // on rajoute une classe
	    document.querySelector('#slidy').className.add("hide");
	}
    };		
    
    xhr.open("POST", target.form.action);
    xhr.send(fd);
}


var searchUserForm = document.querySelector('#search_user_keyword');
if (searchUserForm)
    searchUserForm.addEventListener("keyup", function(){searchUser(this)});
function searchUser(target) 
{
    var fd = new FormData();
    fd.append("keyword", target.value);
    var xhr = new XMLHttpRequest();
    
    var length = target.value.length;
    
    xhr.onload = function(e)
    {
	document.querySelector('#usersList').innerHTML = '<p>' + xhr.responseText + '</p>';
	
	if (length > 1)
	{
	    document.querySelector('#slidy').classList.add("animation-none"); // on rajoute une classe
	}
	
	if (length < 1)
	{
	    document.querySelector('#slidy').classList.add("slidy-down"); // on rajoute une classe
	    document.querySelector('#slidy').className.add("hide");
	}
    };		
    
    xhr.open("POST", target.form.action);
    xhr.send(fd);
}

//function messagesSearch(target) 
//{
//    var fd = new FormData();
//    fd.append("keyword", target.value);
//    var xhr = new XMLHttpRequest();
    
//    var length = target.value.length;
    
//    xhr.onload = function(e)
//    {			
//	document.querySelector('#messages-search-results').innerHTML = '<span>' + xhr.responseText + '</span>';
	
//	if (length > 1)
//	{
//	    document.querySelector('#slidy').classList.add("animation-none"); // on rajoute une classe
//	}
	
//	if (length < 1)
//	{
//	    document.querySelector('#slidy').classList.add("slidy-down"); // on rajoute une classe
//	    document.querySelector('#slidy').className.add("hide");
//	}
//    };		
    
//    xhr.open("POST", target.form.action);
//    xhr.send(fd);
//}





